import React from "react";
import Link from "next/link";

const Nav = () => {
  return (
    <>
      <ul className="mainmenu">
        <li>
          <Link href="#">Welcome</Link>
        </li>
        <li className="with-megamenu has-menu-child-item position-relative">
          <a href="#partnerships">Partnerships</a>
        </li>
        <li className="with-megamenu has-menu-child-item position-relative">
          <a href="#howitworks">How it works ?</a>
        </li>
        <li className="with-megamenu has-menu-child-item position-relative">
          <a href="#solutions">Solutions</a>
        </li>
        <li className="with-megamenu has-menu-child-item position-relative">
          <a href="#pricing">Pricing</a>
        </li>
      </ul>
    </>
  );
};

export default Nav;
